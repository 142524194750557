/* global */
.header {
    background: #fb1;
    padding: 1rem 8rem 1rem 5rem;
}

.header a {
    color: #222;
    text-decoration: none;
    cursor: pointer;
}

/* desktop */
.desktop,
.desktop ul {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.desktop ul {
    list-style: none;
}

.desktop ul li {
    margin-right: 1rem;
}

.desktop .blockBtn{
    display: flex;
    align-items: center;
}

.desktop .cadastro{
    padding: .5rem 1rem;
    border-radius: .5rem;
    border: 1px solid #1E310F;
    margin-right: 1rem;
}

.desktop .cadastro:hover{
    background: #1E310F;
    color: #fff;
}

.desktop .login {
    background: #1E310F;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: .5rem;
}

/* mobile */

.mobile {
    display: none;
}

@media (max-width: 1020px) {

    /* header */
    .header {
        background: #fb1;
        padding: 1rem 5rem;
    }

    /* desktop */
    .desktop {
        display: none !important;
    }

    /* mobile */
    .mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .mobile .btnMobile {
        background: #eee;
        width: 40px;
        height: 40px;
        border: 1px solid transparent;
        border-radius: .3rem;
        cursor: pointer;
    }

    .blocoItens {
        display: none;
    }

    .blocoItensActive {
        display: flex;
        flex-direction: column;
        background: #fb1;
        box-shadow: 0 3px #eee;
        position: absolute;
        top: 3.5rem;
        right: 0;
        z-index: 100;
        border-radius: .3rem;
        padding: .5rem 1rem;
    }

    .blocoItensActive ul {
        list-style: none;
    }

    .blocoItensActive ul li{
        margin-bottom: .5rem;
    }
}

@media (max-width: 40rem) {
    .header {
        padding: 1rem;
    }
}