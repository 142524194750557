.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.modalContent {
    margin-top: 1.5rem;
}

.formGroup {
    margin-bottom: 1rem;
}

.formLabel {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #333;
}

.modalButton {
    margin-top: 1rem;
}

.detailItem {
    margin-bottom: 0.5rem;
}