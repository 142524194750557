.inscription {
    padding: 1rem 4.5rem;
    background: #f5f5f5;
}

.card {
    background: #fff;
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: .5rem;
}

.paragraph {
    font-size: 1.3rem;
    margin-top: 1rem;
}

.row {
    display: flex;
    width: 100%;
}

.row div:nth-child(1) {
    margin-right: .5rem;
}

.row div:nth-child(2) {
    margin-left: .5rem;
}

.card .buttonSave {
    color: #333;
    background: #E19E06;
    padding: .8rem 15rem;
    font-size: 16px;
    border: 1px solid transparent;
}

.card .subtitle {
    color: #333;
    font-size: 1.1rem;
}

.card .button:hover,
.card .buttonSave:hover {
    box-shadow: 0 0 0 3px #fea;
    background: #E19E06 !important;
    border-color: #fb1;
}

.blockInput {
    width: 100%;
}

.inscription h1,
.inscription p {
    margin-left: .5rem;
}

/* bloco de formulario */
.blockForm {
    margin-top: 1.5rem;
}

/* .blockForm form div {
    display: flex;
} */

.card h4 {
    color: #212121;
    margin-left: .5rem;
    margin-top: 1.5rem;
}

.blockForm .button {
    background: #E19E06 !important;
    color: #222 !important;
    padding: .8rem 1rem !important;
    width: 100%;
}


/* responsividade */
@media (max-width: 1028px) {
    .inscription {
        padding: 1rem 5rem;
    }
}

@media (max-width: 833px) {
    .inscription {
        padding: 1rem 5rem;
    }

    .blockForm form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .blockForm form div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row div:nth-child(1) {
        margin-right: 0 !important;
    }
    
    .row div:nth-child(2) {
        margin-left: 0 !important;
    }

    .blockForm .button {
        background: #E19E06 !important;
        color: #222 !important;
        padding: 1rem auto !important;
    }
}

@media (max-width: 40rem) {
    .inscription {
        padding: 1rem;
    }

    .blockForm form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .blockForm form div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}

/* form dois */
.btnForm {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    height: 3rem;
    text-align: center;
    font-size: 16px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: .4rem;
    font-size: 16px;
    font-weight: bolder;
    font-family: 'roboto';
    text-transform: uppercase;
    width: 98.5%;
}

.btnSave {
    color: #1E310F;
    background: #E19E06;
    margin-left: 1rem;
}

.btnBack {
    background: #1E310F;
    color: #fff;
    margin-left: .5rem;
}

.btnSave:hover {
    box-shadow: 0 0 0 3px #fea;
    border-color: #fb1;
}

@media (max-width: 40rem) {
    .blockBtn {
        margin-left: 1rem;
    }

    .btnForm {
        margin-top: 0;
        width: 100%;
    }

    .btnBack {
        margin-left: -.5rem !important;
    }

    .rowBackground {
        width: 100%;
        margin-left: 10rem;
        padding: 0 !important;
        margin: 0;
    }
}