.footer {
    background: #1E310F;
    color: #fff;
}

.footer section:nth-child(1) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 5rem 5rem 2rem 8rem;
}

.footer h3 {
    margin-bottom: 1rem;
}

.footer ul {
    list-style: none;
}

.footer ul li {
    margin-bottom: 1rem;
}

.footer ul li a {
    color: #fff;
    text-decoration: none;
}

.footer section:nth-child(2) {
    padding-bottom: 3rem;
    text-align: center;
}

.blockImage img {
    width: 12rem;
}

@media (max-width: 1261px) {

    .footer section:nth-child(1) {
        padding: 5rem 2rem;
    }
}

@media (max-width: 780px) {
    .blockImage {
        display: none;
    }

    .footer section:nth-child(1) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding: 5rem 2rem;
    }
}

@media (max-width: 40rem) {
    .footer section:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer section:nth-child(1) div{
        text-align: center;
    }
}