.toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.contentBtns {
    display: flex;
    gap: 10px;
}

.searchBar {
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 5px;
    width: 780px;
}

.searchBar input {
    border: none;
    outline: none;
    padding: 5px;
    width: 100%;
}

.searchBar input::placeholder {
    color: #B0B0B0;
}

.searchBar button {
    color: #1E310F;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
}

.actionButton {
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
}

.addSchool {
    background-color: #4CAF50;
    color: white;
}

.addSchool:hover {
    background-color: #45a049;
}

.addSchool:active {
    background-color: #3e8e41;
}

.addSchool:focus {
    outline: none;
}

.addSchool:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.filterSchools {
    background-color: #008CBA;
    color: white;
}

.filterSchools:hover {
    background-color: #007bb5;
}

.filterSchools:active {
    background-color: #005f8d;
}

.filterSchools:focus {
    outline: none;
}

.deleteSchool {
    background-color: #f44336;
    color: white;
}

.deleteSchool:hover {
    background-color: #d32f2f;
}

.deleteSchool:active {
    background-color: #c62828;
}

.deleteSchool:focus {
    outline: none;
}