.wrapper {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    width: 100%;
}

.wrapper label {
    display: block;
    font-size: 16px;
    color: #333;
}

.wrapper select {
    display: block;
    width: 100%;
    padding: .8rem .5rem;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: .3rem;
    font-size: 16px;
    cursor: pointer;
}

.wrapper select:hover,
.wrapper select:focus {
    background: #fff;
    border-color: #fb1;
    box-shadow: 0 0 0 3px #fea;
}

.wrapper select option{
    background: #eee;
    cursor: pointer;
}
.wrapper .errorMessageBorder {
    border: 1px solid rgb(209, 32, 32);
}

.wrapper .errorMessage {
    color: rgb(209, 32, 32);
}

.wrapper .errorMessageBorder * {
    color: rgb(209, 32, 32);
}
@media (max-width: 40rem) {
    .wrapper {
        display: flex;
        flex-direction: column;
        margin: 1rem auto;
        width: 100%;
    }
}