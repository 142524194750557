/* reset */
* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}


img {
    max-width: 100%;
    display: block;
}

.username {
    color: #333;
    text-align: left;
    text-transform: capitalize;
}

.email {
    color: #333;
    text-transform: lowercase;
    font-weight: 400;
}

.login {
    color: #333;
    text-decoration: none;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.ml-1 {
    margin-left: .5rem;
}

.label {
    margin-top: .5rem;
    color: #333 !important;
}

.border-none {
    border: none;
    font-size: 1rem;
}

.btn-success {
    background: #346CB0;
    color: #fff;
    padding: .9rem 1.5rem;
    text-decoration: none;
    margin-bottom: 4rem;
    border-radius: .2rem;
    cursor: pointer;
}

.btn-error {
    background: #b03434;
    color: #fff;
    padding: .9rem 1.5rem;
    text-decoration: none;
    border-radius: .2rem;
    cursor: pointer;
}

.title {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2rem;
    z-index: 1;
    position: relative;
    color: #333;
}

.title::after {
    content: '';
    display: block;
    height: 5px;
    width: 1rem;
    background: #E19E06;
    position: absolute;
    top: 30px;
    border-radius: .2rem;
    left: 0;
    z-index: -1;
}

.nota-success,
.nota-danger {
    padding: .1rem 1rem;
    border-radius: 1rem;
}

.nota-success {
    background: #c2ffc2;
    color: green;
}

.nota-danger {
    background: #fdc5c5;
    color: red;
}

/* tables */
.title-table {
    color: #333 !important;
}

.btn-icon {
    border: 1px solid #aaa;
    padding: 5px 7px 2px 7px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
}

.btn-icon:hover {
    background: #f5f5f5;
}

.btn-icon i {
    color: #aaa;
}

.btn-icon i:hover {
    color: #666;
    border-color: #666;
}

.btn-edit {
    cursor: pointer;
}

/* logo */
.logo {
    color: #333;
    text-decoration: none !important;
}

.logo i {
    margin: 0 .5rem;
}

/* sidebar */
.sidebar-item {
    display: flex;
    align-items: center;
    font-size: 15px !important;
    padding: .5rem 1rem;
    width: 100% !important;
    text-decoration: none;
    /* color: #333; */
}

.sidebar-item:hover,
.sidebar-item:focus {
    background: #365c1996;
}

.sidebar-item-icon {
    margin-right: 1.5rem;
    color: #ffffff;
}

.submenu i {
    padding-left: 1.5rem;
    padding-right: 1rem;
}

#font-item {
    font-size: 10px !important;
}

/* cursos */
.curso-activo {
    background: #D8F0DF;
    color: #377D3F;
    padding: .5rem .8rem;
    border-radius: 5rem;
}

.curso-inactivo {
    background: #F4E7DF;
    color: #F9302B;
    padding: .5rem .8rem;
    border-radius: 5rem;
}

.cel-name {
    min-width: 13.5rem;
}

.cel-years {
    min-width: 8rem;
}

/* bloco de pesquisa e botões de adição e lixeira */
.bloco-pesquisa-botao {
    display: flex;
    border-bottom: 1.5px solid #ededed;
    padding-bottom: 1rem;
}

.bloco-pesquisa-botao .search {
    border: 1px solid transparent;
    height: 3rem;
    display: flex;
    align-items: center;
    border-radius: .4rem;
    margin-right: 1rem;
    background: #fff;
}

.bloco-pesquisa-botao .search:hover,
.bloco-pesquisa-botao .search:focus {
    border-color: #E19E06;
    box-shadow: 0 0 0 3px #fea;
}

.bloco-pesquisa-botao .search input {
    height: 100%;
    border: 1px solid #ededed;
    padding: .8rem .5rem;
    border-radius: .4rem 0 0 .4rem;
    width: 12.5rem;
    font-size: 1rem;
    background: #f5f5f5;
}

.bloco-pesquisa-botao .search input:hover,
.bloco-pesquisa-botao .search input:focus {
    background: #fff;
    outline: none;
}

.bloco-pesquisa-botao .search button {
    height: 100%;
    width: 3rem;
    border: none;
    cursor: pointer;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
}

.bloco-pesquisa-botao .search button i {
    font-size: 16px;
    color: #757575;
}

.bloco-pesquisa-botao .btn {
    margin-right: 1rem;
    height: 100%;
    border-radius: .2rem;
    padding: .6rem 1rem;
    color: #f5f5f5;
    text-decoration: none;
    max-width: 15.5rem;
}

.bloco-pesquisa-botao .btn-add {
    background: #E19E06;
    color: #333;
    border: 1px solid transparent;
}

.bloco-pesquisa-botao .btn-select {
    cursor: pointer;
    background: #E19E06;
    color: #333;
    border: 1px solid transparent;
    padding: 14px 18px;
    font-size: 16px;
    border-radius: 5px;
}

.btn-select .icon{
    margin-left: 8px;
}

.bloco-pesquisa-botao .btn-add:hover,
.bloco-pesquisa-botao .btn-select:hover,
.btn-icon-table:hover {
    border-color: #E19E06;
    box-shadow: 0 0 0 3px #fea;
}

.bloco-pesquisa-botao .btn-remove {
    background: #B03434;
}

@media (max-width: 890px) {
    .bloco-pesquisa-botao {
        display: flex;
        flex-direction: column;
    }

    .bloco-pesquisa-botao .btn {
        margin-top: .5rem;
        text-align: center;
    }
}

.btn-icon-table{
    padding: 8px 12px;
    background: #E19E06;
    color: #222;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}