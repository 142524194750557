.container{
    margin-bottom: 2rem;
}

.title{
    font-size: 2rem;
    font-weight: 700;
    color: #1E310F;
    font-family: serif;
}

.title::after
{
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    background-color: #E19E06;
    border-radius: 5px;
    margin-top: -20px;
}

.text{
    font-size: 16px;
    color: gray;
    font-weight: 400;
    margin-top: 0.8rem;
}