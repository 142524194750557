.school-detail {
    width: 100%;
    min-height: 100%;
    padding: 2rem 4rem;
    background-color: #FFFFFF;
}

.school-detail__header {
    margin-bottom: 2rem;
}

.school-detail__title {
    font-size: 2rem;
    font-weight: 700;
    color: #1E310F;
    font-family: serif;
}

.school-detail__title::after {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    background-color: #E19E06;
    border-radius: 5px;
    margin-top: -20px;
}

.school-detail__info {
    display: flex;
    width: 100%;
    height: 300px;
    margin-top: 20px;
    align-items: end;
    padding: 1rem 2rem;
    border-radius: 10px;
    justify-content: space-between;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(180deg, #f4f4f4 0%, #ababab 100%);
}

.school-detail__info-data h2 {
    font-size: 16px;
    font-weight: 600;
    color: #1E310F;
    margin-top: 0.5rem;
}

.school-detail__info-data p {
    font-size: 14px;
    color: #666666;
}

/* Imagem (avatar) da escola */
.school-detail__avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}


.school-detail__action-btn {
    border: none;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 8px;
    padding: 5px 10px;
    color: #FFFFFF;
    border-radius: 15px;
    text-decoration: none;
    background-color: #8e8e8ea2;
    transition: background-color 0.3s;
}

.school-detail__action-btn--active {
    background-color: #22C55E;
}

.school-detail__action-btn:hover {
    background-color: #8e8e8ed9;
}

.school-detail__options {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.school-detail__option-btn--suspend,
.school-detail__option-btn--edit,
.school-detail__option-btn--delete {
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 8px;
    border: none;
    color: inherit;
    border-radius: 5px;
    text-decoration: none;
    background-color: #FFFFFF;
    transition: background-color 0.3s, color 0.3s;
}

.school-detail__option-btn--suspend {
    border: 1px solid #D18A06;
    color: #D18A06;
}

.school-detail__option-btn--edit {
    border: 1px solid #666666;
    color: inherit;
}

.school-detail__option-btn--delete {
    border: 1px solid #EF4444;
    color: #EF4444;
}

/* ----------------------------- */

.school-detail__tabs {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid #ccc;
}

.school-detail__tab-btn {
    padding: 0.5rem 1rem;
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s, color 0.3s;
}

.school-detail__tab-btn:hover {
    color: #1E310F;
}

.school-detail__tab-btn--active {
    color: #1E310F;
    border-bottom-color: #E19E06;
}

/* ---------------------------------------------- */


.tab-content {
    padding: 2rem 0;
}

.school-info-section {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.school-info-section h2 {
    font-size: 1.5rem;
    color: #1e310f;
    margin-bottom: 1rem;
}

.school-info-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.school-info-section li {
    margin-bottom: 0.75rem;
    font-size: 14px;
    color: #444;
}

.director-section {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.director-section h2 {
    font-size: 1.5rem;
    color: #1e310f;
    margin-bottom: 1rem;
}

.director-section p {
    font-size: 14px;
    color: #444;
    margin-bottom: 0.75rem;
}


.overview-cards {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.overview-card {
    flex: 1 1 calc(25% - 1rem);
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1rem;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s;
}

.overview-card:hover {
    transform: translateY(-3px);
}

.overview-card i {
    font-size: 2rem;
    color: #E19E06;
    margin-bottom: 0.5rem;
}

.overview-card p {
    font-size: 14px;
    color: #666;
    margin-bottom: 0.25rem;
}

.overview-card h3 {
    font-size: 1.25rem;
    color: #1e310f;
    margin: 0;
}

.overview_content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
}

.school-info-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid #ccc;
}

.school-info-item {
    display: flex;
    flex-direction: column;
}

.school-info-text{
    font-size: 20px;
    color: #000000;
    margin-bottom: 0.25rem;
}

.school-info-description{
    margin-top: 1rem;
    font-size: 18px;
    margin-bottom: 0.25rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
}

.school-info-description-text{
    font-size: 14px;
    color: #666;
    margin-bottom: 0.25rem;
}



.director-section {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.director-section h2 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 700;
}


.director-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.director-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}


.director-info-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.director-info-item {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}


.director-info-item h4 {
    font-size: 14px;
    color: #666;
    font-weight: 700;
    margin-bottom: 0.5rem;
}


.director-info-item p {
    font-size: 16px;
    color: #333;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.director-info-item i {
    margin-right: 0.5rem;
    color: #D18A06;
    font-size: 18px;
}


@media (max-width: 600px) {
    .director-info-list {
        grid-template-columns: 1fr;
    }
}