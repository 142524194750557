/* Estilos Globais */
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #ffffff;
    min-height: calc(100vh - 77px);
}

.contentImage {
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(30, 49, 15, 0.776), rgba(30, 49, 15, 0.667)),
        url('https://eipg.com.br/wp-content/uploads/2024/03/10-fatores-para-escolher-uma-boa-escola.jpg');
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    font-family: serif;
    text-align: center;
    color: white;
}

.contentImageContainer {
    max-width: 800px;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.contentImageLogo {
    width: 100px;
    height: 100px;
    margin: 0 auto 1rem auto;
    background: #FFBB11;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentImageTitle {
    font-size: 2.5rem;
    margin-bottom: .5rem;
    font-weight: 700;
}

.contentImageTitle::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #FFBB11;
    border-radius: 10px;
    margin: 1rem auto;
}

.contentImageText {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.contentImageList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.contentImageListItem {
    background: rgba(255, 255, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.contentImageListItem:hover {
    background: rgba(255, 255, 255, 0.4);
}

.icon {
    font-size: 1.2rem;
    color: #FFBB11;
}

/* Estilos do Formulário */
.form {
    background-color: #F4F6F7;
}

.formContainer {
    padding-left: 8rem;
    padding-right: 8rem;
}

.formTitle {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.2rem;
    font-weight: 700;
    text-align: center;
    font-family: serif;
    color: #1E310F;
}

.formTitle::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #FFBB11;
    border-radius: 10px;
    margin: 0.1rem auto;
}

.paragrafo {
    text-align: center;
    margin: 0 auto 1rem auto;
    font-size: 1.2rem;
    color: gray;
    width: 70%;
    padding: 0 1rem;
    line-height: 1.5;
    font-family: serif;
    font-weight: 400;
    font-style: italic;
}

.contentForm {
    padding: 1rem 1rem;
    border-radius: 5px;
    background-color: #fff;
    border-top: 3px solid #FFBB11;
}

.formGroupItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.contentGroupForms {
    background-color: #F9FAFB;
    padding: .3rem 1rem;
    margin-top: .5rem;
    border-radius: 10px;
}

.blockBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
}

.formText{
    font-size: 16px;
    color: gray;
}