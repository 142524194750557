/* banner */
.banner {
    min-height: 91vh;
    width: 100%;
    display: flex;
    padding: 6rem 8rem;
    background: url('../assets/images/image-banner.jpg') no-repeat center center;
    background-size: cover;
}

.banner::before {
    content: "";
    position: absolute;
    top: 77px;
    left: 0;
    width: 100%;
    min-height: 91vh;
    background-color: rgba(0, 0, 0, .6);
    z-index: 1;
}

.banner * {
    position: relative;
    z-index: 2;
}

.banner div {
    width: 50%;
}

/* bloco de texto */

.blocoTexto h1 {
    max-width: 25rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-size: 2rem;
    color: #fff !important;
}

.blocoTexto p {
    max-width: 30rem;
    text-align: justify;
    margin-bottom: 1.5rem;
    color: #fff;
}

.blockBtn{
    display: flex;
    align-items: center;
}

.blockBtn > button:nth-child(2){
    margin-left: 1rem;
}

.blocoTexto button {
    background: #E19E06;
    padding: .8rem 1.5rem;
    border: 1px solid transparent;
    color: #333;
    font-size: 16px;
    border-radius: .4rem;
    cursor: pointer;
    text-transform: uppercase;
}

.blocoTexto button:hover {
    outline: none;
    border-color: #E19E06;
    box-shadow: 0 0 0 3px #fb1;
}

/* bloco de qualidade */
.blocoQualidade {
    margin-top: 1.5rem;
}

.blocoQualidade ul {
    display: flex;
    margin-left: 1rem;
}

.blocoQualidade ul li {
    margin-right: 2rem;
    font-size: 1rem;
    font-family: 'Roboto';
}

/* bloco de imagem */
.blocoImagem {
    display: none;
    background: transparent !important;
}

.blocoImagem .image {
    width: 100%;
    min-height: 25rem;
}

/* resposive */
@media (max-width: 1122px) {

    /* banner */
    .banner {
        display: flex;
        flex-direction: column;
        padding: 2rem 6rem;
    }

    .banner div {
        width: 100%;
    }

    /* bloco de qualidade */
    .blocoQualidade {
        margin-top: 1rem;
    }

    .blocoQualidade ul {
        display: flex;
        flex-direction: column;
    }

    .blocoQualidade ul li {
        margin-right: 2rem;
        font-size: 1rem;
        font-family: 'Roboto';
    }

    /* bloco de qualidade */
    .blocoQualidade {
        margin-top: 1.5rem;
    }

    /* bloco de imagem */
    .blocoImagem {
        display: none;
    }
}

/* resposive */
@media (max-width: 813px) {

    /* banner */
    .banner {
        flex-direction: column;
        padding: 2rem 5rem;
    }

    .banner div {
        width: 100%;
    }

    /* bloco de texto  */
    .blocoTexto h1 {
        margin-bottom: 1rem;
    }

    .blocoTexto p {
        text-align: justify;
        margin-bottom: 1.5rem;
    }

    /* bloco de qualidade */
    .blocoQualidade ul {
        display: flex;
        flex-direction: row !important;
    }

    /* bloco de imagem */
    .blocoImagem {
        display: none;
    }
}

@media (max-width: 693px) {

    /* banner */
    .banner {
        display: flex;
        flex-direction: column;
        padding: 0rem 2rem;
    }

    .banner div {
        width: 100%;
    }

    /* bloco de texto  */
    .blocoTexto h1 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    .blocoTexto p {
        text-align: justify;
        margin-bottom: 1.5rem;
    }

    /* bloco de imagem */
    .blocoImagem {
        display: none;
    }
}

@media (max-width: 693px) {
    .blocoQualidade ul {
        display: flex;
        flex-direction: column !important;
    }
}