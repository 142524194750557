.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 1.3rem 0;
}

.card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    border: 1px solid #E0E0E0;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cardContent {
    margin-bottom: 1rem;
}

.cardTitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: #1E310F;
}

.cardData {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardNumber {
    font-size: 2rem;
    font-weight: 700;
    color: #333333;
}

.cardIcon {
    font-size: 2.5rem;
    color: #E19E06;
}

.headerTable{
    background-color: #F9FAFB;
}