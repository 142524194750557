.modal {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vw;
    z-index: 99;
    padding: 15rem 34rem;
}

.modal p {
    margin-top: 1.5rem;
}

.modal button {
    cursor: pointer;
}

/* container */
.container {
    background: #fff;
    padding: 1rem 2rem 2rem 2rem;
    border-radius: .4rem;
    min-width: 30rem !important;
    max-width: 35rem !important;
}

/* header */
.container .hedaer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container .hedaer .close {
    background: #f31;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: .4rem .6rem;
    color: #eee;
}

/* body */

.container .body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.body a {
    background: #D28A01;
    padding: .5rem 1rem;
    border: 1px solid transparent;
    font-size: 16px;
    border-radius: .2rem;
    color: #1C300C;
    text-decoration: none;
    cursor: pointer;
}

.body a.nacional {
    margin-right: 1rem;
}

/* responsividade */
@media (max-width: 1200px) {
    .modal {
        padding: 15rem 20rem;
    }
}

@media (max-width: 1080px) {
    .modal {
        padding: 15rem 12rem;
    }
}

@media (max-width: 786px) {
    .modal {
        padding: 15rem 10rem;
    }
}

@media (max-width: 76px) {
    .modal {
        padding: 15rem 10rem;
    }
}

@media (max-width: 731px) {
    .modal {
        padding: 15rem 4rem;
    }
}

@media (max-width: 40rem) {
    .modal {
        padding: 18rem 1rem;
    }

    .hedaer {
        align-items: start !important;
    }

    .close {
        margin-top: .5rem;
    }

    .modal p {
        margin-top: 1rem;
    }

    .container {
        padding: 1rem 1rem 2rem 1rem;
        min-width: 12rem !important;
    }
}